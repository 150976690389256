<app-spiner *ngIf="spinner"></app-spiner>
<app-header></app-header>
<app-register *ngIf="client === null" (sendAction)="sendCode($event)" (terms)="sendTerms($event)"></app-register>
<app-benefits
  *ngIf="client !== null" [client]="client"
  (selectAction)="selectGift($event)">
</app-benefits>
<app-formulario
  *ngIf="client !== null && gift !== null" [client]="client" [gift]="gift" (closeAction)="closeForm2()"
  (formAction)="updateClient($event)"
  (spinnerAction)="setSpinner($event)">
</app-formulario>
<app-footer></app-footer>
<div class="pop-up-benefit ok" *ngIf="showPopup">
  <div class="pop-up-benefit-background"></div>
  <div class="pop-up-benefit-content animate__bounceIn">
    <div class=" pop-up-header">
      <img class="img" src="./assets/images/ok.png">
    </div>
    <div class="pop-up-content">
      <h1>Tu IMEI se registró exitosamente</h1>
      <p>Hola, el IMEI que ingresaste es válido, ahora podrás disfrutar tu beneficio.</p>
      <div class="row justify-content-center">
        <div class="botones" (click)="close()">
          <p style="font-size: 16px;color:white">Cerrar</p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="pop-up-benefit error" *ngIf="showPopupError">
  <div class="pop-up-benefit-background"></div>
  <div class="pop-up-benefit-content animate__bounceIn">
    <div class="pop-up-header-error">
      <img class="img" src="./assets/images/close.png">
    </div>
    <div class="pop-up-content">
      <h1>¡Algo salió mal!</h1>
      <p>{{msgError}}</p>
      <div class="row justify-content-center">
        <div class="botones" (click)="closeError()">
          <p style="font-size: 16px;color:white">Cerrar</p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="pop-up-benefit " *ngIf="termsPopup">
  <div class="pop-up-benefit-background"></div>
  <div class="pop-up-benefit-content animate__bounceIn">
    <div class=" pop-up-header">
      <img class="img" src="./assets/images/chulo.png">
    </div>
    <div class="pop-up-content" style="padding: 30px 30px;     overflow: auto;
    height: calc(100% - 118px);">
      <h1 >TERMINOS Y CONDICIONES</h1>
      <p style="font-size: 10px; text-align: left;">PROMOCIÓN válida(i) para canales online desde el día 23 de septiembre de 2021 hasta el día 6 de octubre de
        2021, y (ii) para para canales físicos desde el día 27 de septiembre de 2021 hasta el día 6 de octubre de 2021.
        En ambos casos, la promoción irá hasta su fecha de finalización o hasta agotar existencias, lo que ocurra
        primero. Son 1130 unidades disponibles de PRODUCTOS. “Preventa Offline Foldables” [Por la compra de un SAMSUNG
        GALAXY ZFlip 3 (SM-F711BZ), el CLIENTE recibirá (1) Samsung Galaxy BUDS 2 (SM-R177N), y encontrarás en la caja
        una tarjeta con un (1) Evoucher para redimir un (1) beneficio adicional. Por la compra de un SAMSUNG GALAXY
        ZFlip 3 (SM-F711BZ), el CLIENTE recibirá (1) Samsung Galaxy BUDS 2 (SM-R177N), y encontrará en la caja una
        tarjeta con un (1) Evoucher para redimir un (1) beneficio adicional, o por la compra de un SAMSUNG GALAXY ZFold
        3 (SM-F926BZ), el CLIENTE recibirá (1) Samsung Galaxy Watch 4.40mm (SM-R860N), y encontrará en la caja una
        tarjeta con un (1) Evoucher para redimir un (1) beneficio adicional. Los PRODUCTOS y BENEFICIOS se entregarán a
        partir del 27 de septiembre de 2021. Los BENEFICIOS del Evoucher deberán redimirse por medio de
        samsungparati.com hasta el 30 de diciembre de 2021. Pasada dicha fecha, no podrás disfrutarlos. La PROMOCIÓN
        será aplicable a nivel nacional, mediante los canales online de las Tiendas Autorizadas Participantes. Los
        PRODUCTOS vienen únicamente con el cable de datos USB tipo C. El adaptador de carga, audífonos, así como
        cualquier otro accesorio se venden por separado. Utilice adaptadores de carga genuinos. El uso de otros
        adaptadores podría reducir el desempeño de la batería. El funcionamiento en 5G de los PRODUCTOS dependerá de la
        habilitación de la red 5G por parte del operador. Los servicios de roaming están habilitados en red 4G hasta que
        su operador lo indique. La velocidad variará de acuerdo a las condiciones del lugar y operador. Para mayor
        información comuníquese con su operador. El BENEFICIO no es canjeable por dinero en efectivo, por abonos a
        cuentas bancarias, abonos a tarjetas de crédito o débito, ni por cualquier otro beneficio diferente a lo
        expresado en los T&C de la PROMOCIÓN. Esta PROMOCIÓN es acumulable con otras promociones vigentes siempre y
        cuando así se informe expresamente. Los precios de venta, condiciones y tiempos de envío y entrega de los
        PRODUCTOS y/o BENEFICIOS dependerán de cada Tienda Autorizada Participante. Los detalles de la promoción se
        podrán consultar en <a href="https://www.samsung.com/co/offer" target="_blank" rel="noopener noreferrer"> www.samsung.com/co/offer. </a></p>
      <div class="row justify-content-center">
        <div class="botones" (click)="closeTerms()">
          <p style="font-size: 16px;color:white" >Cerrar</p>
        </div>
      </div>
    </div>
  </div>
</div>
