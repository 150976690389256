import { Component, OnInit } from '@angular/core';
import { MethodsService } from '@services/methods.service';
import { Client } from '@models/client';
import { Gift } from '@models/gift';
import {GiftType} from "@models/gift-type";
import {CodeType} from "@models/code-type";
import {VoucherCampaign} from "@models/voucher-campaign";
import {Redemption} from "@models/redemption";
declare var Swal: any;

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  public client: Client | null = null;
  public gift: Gift | null = null;
  public showPopup: boolean;
  public showPopupError: boolean;
  public msgError: string;
  public dataCampaign: string;
  public spinner = false;
  public termsPopup: boolean;

  constructor( public servicio: MethodsService ) { }

  ngOnInit(): void {}

  public sendCode = (code: string) => {
    this.spinner = true;
    /* this.servicio.sendCode(code).subscribe((data: any) => {
      if (data.success) {
        this.spinner = false;
        this.showPopup = true;
        this.client = {
          voucher: data.voucher,
          activeCampaigns: data.activeCampaigns
        };
      }else{
        this.spinner = false;
        this.showPopupError = true;
        this.msgError = data.error.error;
      }
    }); */

    this.servicio.sendImei(code).subscribe((data: any) => {
      if (data.success) {
        this.spinner = false;
        this.showPopup = true;
        this.client = {
          // voucher: data.voucher,
          voucher: {
            voucherCodeID: 1,
            codeTypeID: 1,
            voucherCampaignID: 2,
            codVoucher: code,
            cantGiftRedeemed: 1,
            isActive: true,
            codeType: {
              codeTypeID: 1,
              typeName: 'General'
            },
            voucherCampaign: {
              voucherCampaignID: 1,
              campaignName: 'test',
              campaignDescription: 'description',
              campaignImage: 'image',
              campaignValue: 100000,
              cantGift: 1000,
              isActive: true,
              startDate: new Date(),
              finishDate: new Date(),
              listVoucherCodes: []
            },
            redemtions: [],
            clienteServicios: '',
            clienteEntretenimientos: ''
          },
          activeCampaigns: data.activeCampaigns
        };
      }else{
        this.spinner = false;
        this.showPopupError = true;
        this.msgError = data.msg;
      }
    });
  }

  close = () => this.showPopup = false;
  closeError = () => this.showPopupError = false;

  public selectGift = (gift: Gift) => this.gift = gift;
  public closeForm = () => { this.gift = null; }
  public closeForm2 = () => { window.location.reload(); }

  public updateClient = (data: any) => {
    this.client.voucher = data.voucherCod;
  }

  public sendTerms = (event) => {
    this.termsPopup = event;
  }

  public closeTerms = () => this.termsPopup = false;

  public setSpinner = (spinnerBoolean: boolean) => {
    if (!spinnerBoolean) {
      setTimeout(() => {
        this.spinner = spinnerBoolean;
      }, 500);
    } else {
      this.spinner = spinnerBoolean;
    }
  }
}
