<div class="row">
  <div class="col-lg-6">
    <div class="txtVoucher">
      <h1>Redime tu<br>e-voucher</h1>
      <p>Para redimir tu beneficio ingresa el IMEI de tu celular.</p>
    </div>
  </div>

  <div class="col-lg-6" style="padding-right: 0px;" >
    <form ngNativeValidate [formGroup]="forma" (ngSubmit)="send()">
      <div class="Code">
        <h1 style="color: black; text-align: center;">Registro</h1>
        <p class="subtitle">Accede a tu beneficio ingresando el IMEI de tu celular<br>
          * Obtenlo marcando *#06# en tu dispositivo móvil</p>
        <input type="text"
               placeholder="Ingresa tu IMEI*"
               class="form-control codeInput"
               name="codeInput"
               formControlName="code"
               [maxLength]="15"
               [class.is-invalid]="getCode"
               required>
        <small *ngIf="getCode">Por &nbsp;favor ingresa un IMEI. Campo obligatorio (*)</small>
        <br>

        <div class="labels" style="margin-top: 63px;">
          <input id="politica" type="checkbox"  formControlName="politic" [class.is-invalid]="getPolitic" required>
          <p class="pspecial" >  Acepto la  <b> <a href="https://www.samsung.com/co/proteccion_de_datos/" target="_blank"> política de privacidad de Samsung Electronics S.A.*</a></b></p>
        </div>

        <div class="labels">
          <input id="terms"  type="checkbox" formControlName="terms" [class.is-invalid]="getTerms" required>
          <p class="pspecial">  Acepto los  <b> <a href="https://www.samsung.com/co/info/tyc/" target="_blank"> términos y condiciones*</a></b></p>
        </div>


        <div class="row justify-content-center">
          <button class="Frame-28" type="submit"> <p>Validar</p> </button>
        </div>


      </div>
    </form>
  </div>

</div>
