<!-- este popup es el del fomulario pequeño  de registro datos persona -->
<div class="pop-up-form-registro" *ngIf="showPopupRegistro">
  <div class="pop-up-form-background" (click)="closeForm()"></div>
  <div class="pop-up-form-content-registro animate__bounceIn">
    <div class="pop-up-header-registro">
      <img class="img" src="./assets/images/ok.png">
    </div>
    <div class="pop-up-content-registro">
      <h1>¿Estás seguro de redimir este e-voucher?</h1>
      <div class="form border boxDescription">
        <h1>{{ gift.campaignName }}</h1>
        <!-- p>{{ gift.tiempoCobertura }}</p -->
      </div>
      <p class="warning">Por favor confirma que el e-voucher que seleccionaste es el que quieres redimir, si es así presiona en “estoy seguro” de lo contrario en “cancelar”.</p>
      <h1 class="register">Registra tu servicio</h1>
      <p class="reg-des">Por favor completa la siguiente información para hacer efectiva la entrega de tu beneficio.</p>
      <form  [formGroup]="forma" *ngIf="gift.giftType.giftTypeID === 2" (ngSubmit)="register('entretenimiento')">
        <div class="form-row">
          <div class="form-group col-md-12">
            <input type="text"
                   class="form-control"
                   style="background-image: none !important;"
                   name="name"
                   formControlName="name"
                   [class.is-invalid]="getName"
                   placeholder="Ingresa tu nombre*"
                   required>
            <p class="small" *ngIf="getName">Por &nbsp;favor Ingresa al menos 3 letras. Campo obligatorio (*)</p>
          </div>
          <div class="form-group col-md-12">
            <input type="text"
                   class="form-control"
                   name="last_name"
                   formControlName="last_name"
                   [class.is-invalid]="getLastName"
                   placeholder="Ingresa tus apellidos*"
                   required>
            <p class="small" *ngIf="getLastName">Por &nbsp;favor Ingresa al menos 3 letras. Campo obligatorio (*)</p>
          </div>
        </div>
        <div class="form-group">
          <input type="email"
                 class="form-control"
                 name="mail"
                 formControlName="mail"
                 [class.is-invalid]="getMail"
                 placeholder="Ingresa tu correo electrónico*"
                 required>
          <p class="small" *ngIf="getMail">Por &nbsp;favor Ingresa un mail valido. Campo obligatorio (*)</p>
        </div>
        <div class="col-12 estilo-foto">
          <div class="output_image_web">
          </div>
          <div class="txt-upload">
            <img src="./assets/images/icon-upload.png" alt="" srcset="">
            <h1>Selecciona los archivos </h1>
            <p>Suelta el archivo aquí.
              Tamaño recomendado imagen:
              1920px x 1080px
            </p>
            <p class="error" id="file_error" style="text-align: center;">Por &nbsp;favor debes cargar una imagen (jpg,png)(*)</p>
          </div>

          <p style="margin-top: 50px;">Por &nbsp;favor confirma que el E-voucher que seleccionaste es el que quieres redimir, si es asi presiona en “estoy
            seguro” de lo contrario en “Cancelar”.</p>
          <input class="col-12" type="file" name="file" (change)="selectFile($event)" required>
        </div>
        <div class="col-12">
          <div class="row justify-content-center" style="margin-top: 75px;">
            <div class="col-sm-12 col-md-6 col-lg-6" >
              <p class="cancel" (click)="closeForm()">
                Cancelar
              </p>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6" id="contButton">
              <button class="botones" type="submit">Estoy seguro</button>
            </div>
          </div>
        </div>
      </form>
      <form [formGroup]="formaServicio" *ngIf="gift.giftType.giftTypeID === 1" (ngSubmit)="register('servicio')">
        <div class="form-row">
          <div class="form-group col-md-12">
            <input type="text"
                   class="form-control"
                   id="nombre"
                   name="nombre"
                   formControlName="nombre"
                   [class.is-invalid]="getNombre"
                   placeholder="Ingresa tu nombre*"
            >
            <p class="small" *ngIf="getNombre">Por &nbsp;favor Ingresa al menos 3 letras. Campo obligatorio (*)</p>
          </div>
          <div class="form-group col-md-12">
            <input type="text"
                   class="form-control"
                   id="apellido"
                   name="apellido"
                   formControlName="apellido"
                   [class.is-invalid]="getApellido"
                   placeholder="Ingresa tus apellidos*">
            <p class="small" *ngIf="getApellido">Por &nbsp;favor Ingresa al menos 3 letras. Campo obligatorio (*)</p>
          </div>
          <div class="form-group col-md-12">
            <select id="gender"
                    class="form-control"
                    name="gender"
                    formControlName="gender"
                    [class.is-invalid]="getGender">
              <option selected>Selecciona tu género*</option>
              <option *ngFor="let g of genders"  >{{ g.name }}</option>
            </select>
            <p class="small" *ngIf="getGender">Por favor selecciona un género. Campo obligatorio (*)</p>
          </div>
          <div class="form-group col-md-12">
            <select id="tipodocumento"
                    class="form-control"
                    name="tipo_documento"
                    formControlName="tipo_documento"
                    [class.is-invalid]="getTipo_documento">
              <option selected>Selecciona tu tipo de documento*</option>
              <option *ngFor="let cedula of tiposDocumento"  >{{ cedula.Tipo }}</option>
            </select>
            <p class="small" *ngIf="getTipo_documento">Por favor selecciona un tipo de documento. Campo obligatorio (*)</p>
          </div>
          <div class="form-group col-md-12">
            <input type="text"
                   class="form-control"
                   id="documento"
                   name="documento"
                   formControlName="documento"
                   [class.is-invalid]="getDocumento"
                   placeholder="Ingresa tu No. de documento*">
            <p class="small" *ngIf="getDocumento">Por &nbsp;favor Ingresa al menos 7 caracteres. Campo obligatorio (*)</p>
          </div>

          <div class="form-group col-md-12">
            <input type="text"
                   class="form-control"
                   id="celular"
                   name="celular"
                   pattern="[0-9]+"
                   [maxlength]="10"
                   formControlName="celular"
                   [class.is-invalid]="getCelular"
                   placeholder="Ingresa tu No. de celular*">
            <p class="small" *ngIf="getCelular">Por favor Ingresa al menos 10 caracteres númericos. Campo obligatorio (*)</p>
          </div>
          <div class="form-group col-md-12">
            <input type="email"
                   class="form-control"
                   id="email"
                   name="email"
                   formControlName="email"
                   [class.is-invalid]="getEmail"
                   placeholder="Ingresa tu correo electrónico*">
            <p class="small" *ngIf="getEmail">Por &nbsp;favor Ingresa un email valido. Campo obligatorio (*)</p>
          </div>
          <div class="form-group col-md-12">
            <select id="department"
                    class="form-control"
                    name="department"
                    formControlName="department"
                    [class.is-invalid]="getDepartment"
                    (change)="getCitiesByDeptos($event)">
              <option selected>Selecciona tu departamento*</option>
              <option *ngFor="let d of Departments"  >{{ d.Departamento }}</option>
            </select>
            <p class="small" *ngIf="getDepartment">Por favor selecciona un departamento. Campo obligatorio (*)</p>
          </div>
          <div class="form-group col-md-12" *ngIf="Cities.length > 0">
            <select id="city"
                    class="form-control"
                    name="city"
                    formControlName="city"
                    [class.is-invalid]="getCity">
              <option selected>Selecciona tu municipio*</option>
              <option *ngFor="let c of Cities"  >{{ c.municipio }}</option>
            </select>
            <p class="small" *ngIf="getCity">Por favor selecciona un municipio. Campo obligatorio (*)</p>
          </div>
          <div class="form-group col-md-12">
            <input type="text"
                   class="form-control"
                   id="address"
                   name="address"
                   formControlName="address"
                   [class.is-invalid]="getAddress"
                   placeholder="Ingresa tu dirección*">
            <p class="small" *ngIf="getAddress">Por &nbsp;favor Ingresa al menos 3 letras. Campo obligatorio (*)</p>
          </div>
          <div class="form-group col-md-12">
            <select id="modelo"
                    class="form-control"
                    name="modelo"
                    formControlName="modelo"
                    [class.is-invalid]="getModelo"
            >
              <option selected>Selecciona el modelo del celular Samsung que compraste*</option>
              <option *ngFor="let equipo of Modelos" >{{equipo.Modelo}}</option>
            </select>
            <p class="small" *ngIf="getModelo">Por &nbsp;favor selecciona un modelo. Campo obligatorio (*)</p>
          </div>
          <div class="form-group col-md-12">
            <select id="almacenamiento"
                    class="form-control"
                    name="almacenamiento"
                    formControlName="almacenamiento"
                    [class.is-invalid]="getAlmacenamiento">
              <option selected>Selecciona la capacidad de almacenamiento*</option>
              <option *ngFor="let a of almacenamiento"  >{{ a.name }}</option>
            </select>
            <p class="small" *ngIf="getAlmacenamiento">Por favor selecciona la capacidad de almacenamiento. Campo obligatorio (*)</p>
          </div>
          <div class="form-group col-md-12" style="text-align: left;">
            <label>Selecciona fecha de compra de tu producto Samsung*</label>
            <input type="date"
                   class="form-control"
                   id="fecha_compra"
                   name="fecha_compra"
                   formControlName="fecha_compra"
                   [class.is-invalid]="getFecha"
                   placeholder="Selecciona fecha de compra de tu producto Samsung*"
            >
            <p class="small" *ngIf="getFecha">Por favor ingresa una fecha. Campo obligatorio (*)</p>
          </div>
          <div class="form-group col-md-12">
            <select id="almacen"
                    class="form-control"
                    name="almacen"
                    formControlName="almacen"
                    [class.is-invalid]="getAlmacen">
              <option selected>Selecciona el almacén donde hiciste tu compra*</option>
              <option *ngFor="let punto of Almacen">{{punto.Almacen}}</option>
            </select>
            <p class="small" *ngIf="getAlmacen">Por favor selecciona un almacén. Campo obligatorio (*)</p>
          </div>
          <div class="form-group col-md-12">
            <input type="text"
                   class="form-control"
                   id="imei"
                   name="imei"
                   disabled
                   formControlName="imei"
                   [class.is-invalid]="getImei"
                   placeholder="Por favor digita en tu celular *#06# para conocer tu Imei.Ingresa el IMEI de tu celular*"
            >
            <p class="small" *ngIf="getImei">Por &nbsp;favor solo 15 caracteres. Campo obligatorio (*)</p>
          </div>

          <!-- div class="form-group col-md-12">
            <input
              type="number"
              class="form-control"
              id="cantidad"
              name="cantidad"
              formControlName="cantidad"
              [class.is-invalid]="getCantidad"
              placeholder="Elije  la cantidad de productos Samsung comprados*">
            <p class="small" *ngIf="getCantidad">Por &nbsp;favor Ingresa la cantidad de productos que has comprado. Campo obligatorio (*)</p>
          </div -->
        </div>
        <div class="col-12 estilo-foto">
          <div class="output_image_web">
          </div>
          <div class="txt-upload">
            <img src="./assets/images/icon-upload.png" id="iconUpload" alt="" srcset="">
            <h1>Selecciona los archivos </h1>
            <p style="text-align: center;">Suelta el archivo aquí.
              Tamaño recomendado imagen:
              1920px x 1080px
            </p>
            <p class="error" id="file_error" style="text-align: center;">Por &nbsp;favor debes cargar una imagen (jpg,png)(*)</p>
          </div>
          <input class="col-12" type="file" name="file" id="file" (change)="selectFile($event)" required>
        </div>
        <!-- p style="margin-top: 34px;">Por &nbsp;favor confirma que el E-voucher que seleccionaste es el que quieres redimir, si es asi presiona en “estoy
          seguro” de lo contrario en “Cancelar”.</p -->
        <div class="col-12">
          <div class="row justify-content-center div-buttons">
            <div class="div-cancel col-sm-12 col-md-6 col-lg-6" >
              <p class="cancel" (click)="closeForm()">
                Cancelar
              </p>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6" id="contButton">
              <button class="botones" type="submit">Estoy seguro</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>


<div class="pop-up-benefit ok" *ngIf="showPopup">
  <div class="pop-up-benefit-background"></div>
  <div class="pop-up-benefit-content animate__bounceIn">
    <div class="pop-up-header">
      <img class="img" src="./assets/images/ok.png">
    </div>
    <div class="pop-up-content">
      <h1>¡Felicitaciones has redimido tu beneficio!</h1>
      <!-- p *ngIf="txtEntreteinment" style="text-align: center;">Recibirás un mensaje a tu correo registrado confirmando tu redención de tu cupón. Ahí encontrarás toda la información, tu código para redimir y los pasos a seguir para que empieces a disfrutar lo que Samsung tiene para ti.</p>
      <p *ngIf="txtServicios" style="text-align: center;">Recibirás un mensaje a tu correo registrado confirmando tu redención de servicio. Posteriormente espera hasta 30 días hábiles para recibir tu confirmación directamente de nuestro centro de servicio para que hagas efectiva tu <b>{{gift.campaignName == 'Garantía Extendida' ? 'garantía Extendida.' : 'reemplazo de pantalla interna.'}}</b></p -->
      <p style="text-align: center;">Recuerda revisar tu correo y seguir las instrucciones para hacer efectivo tu e-voucher.</p>
      <div class="row justify-content-center">
        <div class="botones" (click)="closeForm()" >Cerrar</div>
      </div>
    </div>
  </div>
</div>

<div id="error" class="pop-up-benefit error" *ngIf="showPopupError">
  <div class="pop-up-benefit-background"></div>
  <div class="pop-up-benefit-content animate__bounceIn">
    <div class="pop-up-header-error">
      <img class="img" src="./assets/images/close.png">
    </div>
    <div class="pop-up-content">
      <h1>¡Algo salió mal!</h1>
      <p style="text-align: center;
      line-height: 25px;">{{msmError}}</p>
      <div class="row justify-content-center">
        <div class="botones" (click)="close()" >Cerrar</div>
      </div>
    </div>
  </div>
</div>
