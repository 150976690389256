<div class="container-fluid">
    <div class="row justify-content-center">
        <div class="col-lg-12 txt">
            <p>No olvides seguirnos en nuestras redes  </p>
            <h1>3582686</h1>
        </div>
     
    </div>
    <div class="row justify-content-center">
        <div class="col-lg-12 seguidores">
            <p>Seguidores</p>
    
        </div>

    </div>
    <div class="row justify-content-center">
        <div class="col">
            <img src="assets/images/icon-instagram.png" alt="" srcset="">
        </div>
        <div class="col icon"><img src="assets/images/icon-youtube.png" alt="" srcset=""></div>
        <div class="col"><img src="assets/images/icon-facebook.png" alt="" srcset=""></div>
        <div class="col"><img src="assets/images/icon-twitter.png" alt="" srcset=""></div>
    </div>
</div>



