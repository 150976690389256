import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as $ from 'jquery';
import { Gift } from '@models/gift';
import { MethodsService } from '@services/methods.service';
import { User } from '@models/user';
import { Client } from '@models/client';
import { CheckboxRequiredValidator, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-formulario',
  templateUrl: './formulario.component.html',
  styleUrls: ['./formulario.component.css']
})
export class FormularioComponent implements OnInit {
  // Inputs
  @Input() public client: Client;
  @Input() public gift: Gift;
  // Actions
  @Output() closeAction = new EventEmitter<any>();
  @Output() formAction = new EventEmitter<any>();
  @Output() spinnerAction = new EventEmitter<any>();
  // Vars
  public forma: FormGroup;
  public formaServicio: FormGroup;
  public congratulationsPopup = false;
  public tiposDocumento: [] = [];
  public genders: { id: string, name: string }[] = [
    { id: 'Masculino', name: 'Masculino' },
    { id: 'Femenino', name: 'Femenino' }
  ];
  public almacenamiento: { id: string, name: string }[] = [
    { id: '128 GB', name: '128 GB' },
    { id: '256 GB', name: '256 GB' },
    { id: '512 GB', name: '512 GB' }
  ];
  public Modelos: [] = [];
  public Almacen: { id: string, Almacen: string }[] = [
    { id: 'Claro', Almacen: 'Claro' },
    { id: 'Alkosto', Almacen: 'Alkosto' },
    { id: 'Ktronix', Almacen: 'Ktronix' },
    { id: 'Éxito', Almacen: 'Éxito' },
    { id: 'Falabella', Almacen: 'Falabella' },
    { id: 'Cencosud', Almacen: 'Cencosud' }
  ];
  public Departments: any[] = [];
  public Cities: any[] = [];
  public user = new User();
  public imageBase64: any = {
    height: 0,
    timestamp: '',
    uri: '',
    fileName: '',
    data: ''
  };
  public showPopupRegistro = true;
  public showPopupRegistroTwo = false;
  public showPopup = false;
  public showPopupError = false;
  public msmError: string = '';

  public txtEntreteinment:boolean = true;
  public txtServicios:boolean = false;

  constructor(public service: MethodsService, public fb: FormBuilder) { }

  ngOnInit(): void {

    this.ValidatingForm();
    this.getItemsOFselect();
  }

  showRegister = (campaign) => {
    if (campaign.giftType.giftTypeID === 1) {
      this.showPopupRegistroTwo = true;
      this.getItemsOFselect();
    } else {
      this.showPopupRegistro = true;
    }
  }
  closeRegister = () => {
    this.showPopupRegistro = false;
    this.showPopupRegistroTwo = false;
  }

  getItemsOFselect = () => {
    this.service.getTipoDocumento().subscribe((data: any) => {
      this.tiposDocumento = data.data;
    });

    this.service.getModelo().subscribe((data: any) => {
      this.Modelos = data.data;
    });

    this.service.getAlmacen().subscribe((data: any) => {
      this.Almacen = data.data;
    });

    this.service.getDepartments().subscribe((data: any) => {
      this.Departments = data.data;
    });

    this.formaServicio.patchValue({ imei: this.client.voucher.codVoucher })
  }

  public getCitiesByDeptos = (event: any) => {
    var depto = event.target.value;
    const filterDepto = this.Departments.filter(d => d.Departamento === depto);
    this.service.getCitiesByDepartment(filterDepto[0].DepartamentoID).subscribe((data: any) => {
      this.Cities = data.ciudades;
    });
  }

  public selectFile = ($event) => {
    this.getImageBase64($event);
    $('.txt-upload').css('display', 'none');
  }

  private getImageBase64 = (image) => {
    const file: File = image.target.files[0];
    const myDate = new Date();
    const myReader: FileReader = new FileReader();

    myReader.onload = (e) => {
      if (file.type.includes('image')) {
        $('.output_image_web').html('<img style="width: auto; height: 100px; margin: auto;"  src="' + e.target.result + '"/>');
      } else if (file.type.includes('video')) {
        $('.output_image_web').html('<video width="auto" height="250" controls> <source src="' + e.target.result + '" type="' + file.type + '"></video>');
      } else {
        $('.output_image_web').html('<img src="' + e.target.result + '"/>');
      }
    };

    myReader.onloadend = (e) => {
      this.imageBase64.height = file.size;
      this.imageBase64.timestamp = '2019-01-06T17:16:40';
      this.imageBase64.uri = file.name;
      this.imageBase64.fileName = file.name;
      this.imageBase64.data = myReader.result.toString().split(',')[1];
    };
    myReader.readAsDataURL(file);
    return this.imageBase64;
  }

  public register = (tipoCampaña: string) => {
    this.spinnerAction.emit(true);
    if (this.forma.invalid) {
      Object.values(this.forma.controls).forEach(control => {
        control.markAsTouched();
      });
      if (this.formaServicio.invalid) {
        Object.values(this.formaServicio.controls).forEach(control => {
          control.markAsTouched();
        });
        this.spinnerAction.emit(false);
      } else {
        const json = {
          datosCliente: {
            voucherCod: this.client.voucher,
            giftCampaign: this.gift,
            nombres: this.formaServicio.get('nombre').value,
            apellidos: this.formaServicio.get('apellido').value,
            email: this.formaServicio.get('email').value,
            genero: this.formaServicio.get('gender').value,
            tipoDucumento: this.formaServicio.get('tipo_documento').value,
            numeroDucumento: this.formaServicio.get('documento').value,
            celular: this.formaServicio.get('celular').value,
            departamento: this.formaServicio.get('department').value,
            municipio: this.formaServicio.get('city').value,
            direccion: this.formaServicio.get('address').value,
            almacenamiento: this.formaServicio.get('almacenamiento').value,
            fechaCompra: this.formaServicio.get('fecha_compra').value,
            modeloCelular: this.formaServicio.get('modelo').value,
            almacenDeCompra: this.formaServicio.get('almacen').value,
            imeiCelular: this.formaServicio.get('imei').value,
            // cantidadProductosCompra: this.formaServicio.get('cantidad').value,
          },
          imagenFactura: this.imageBase64
        };
        this.service.redimirPremioS23Servicio(json).subscribe((data: any) => {
          this.spinnerAction.emit(false);
          if (data.success) {
            if (this.gift.giftType.giftTypeID === 1) {
              this.showPopupRegistro = false;
              this.showPopup = true;
              // this.formAction.emit(data);
              this.txtEntreteinment = false;
              this.txtServicios = true;
            }
          } else {
            this.showPopupError = true;
            if (data.error.errorCode === 208) {
              this.msmError = data.error.error;
            }else{
              this.msmError = 'Debes adjuntar la imagen de tu factura. Es campo obligatorio.'
            }
          }
        });
      }
    } else {
      console.log(tipoCampaña);
      if (tipoCampaña === 'entretenimiento') {
        console.log('Forma valid');
        const json = {
          voucherCod: this.client.voucher,
          giftCampaign: this.gift,
          nombres: this.forma.get('name').value,
          apellidos: this.forma.get('last_name').value,
          email: this.forma.get('mail').value,
          imagenFactura: this.imageBase64
        };
        this.service.redimirPremioEntretenimiento(json).subscribe((data: any) => {
          this.spinnerAction.emit(false);
          if (data.success) {
            this.showPopup = true;
            if (this.gift.giftType.giftTypeID === 2) {
              this.showPopupRegistro = false;
              this.showPopup = true;
              // this.formAction.emit(data);
              this.txtEntreteinment = true;
              this.txtServicios = false;
            }
          } else {
            this.showPopupError = true;
            if (data.error.errorCode === 208) {
              this.msmError = data.error.error;
            }else{
              this.msmError = 'Debes adjuntar la imagen de tu factura. Es campo obligatorio.'
            }
          }
        });
      } else {
        this.spinnerAction.emit(false);
      }
    }
  }

  public closeForm = () => this.closeAction.emit();
  public close = () => this.showPopupError = false;
  public exitOK = () => {
    this.closeForm();
    location.reload();
  }

  public ValidatingForm = () => {
    this.forma = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      last_name: ['', [Validators.required, Validators.minLength(3)]],
      mail: ['', [Validators.required, Validators.email]],
    });

    this.formaServicio = this.fb.group({
      nombre: ['', [Validators.required, Validators.minLength(3)]],
      apellido: ['', [Validators.required, Validators.minLength(3)]],
      gender: ['', [Validators.required]],
      tipo_documento: ['', [Validators.required]],
      documento: ['', [Validators.required, Validators.minLength(7)]],
      celular: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('[0-9]+')]],
      email: ['', [Validators.required, Validators.email]],
      department: ['', [Validators.required]],
      city: ['', [Validators.required]],
      address: ['', [Validators.required]],
      almacenamiento: ['', [Validators.required]],
      fecha_compra: ['', [Validators.required]],
      modelo: ['', [Validators.required]],
      almacen: ['', [Validators.required]],
      imei: ['', [Validators.required, Validators.minLength(15) , Validators.maxLength(15)]],
      // cantidad: ['', [Validators.required, Validators.minLength(1)]]
    });
  }

  get getName() {
    return this.forma.get('name').invalid && this.forma.get('name').touched;
  }

  get getLastName() {
    return this.forma.get('last_name').invalid && this.forma.get('last_name').touched;
  }

  get getMail() {
    return this.forma.get('mail').invalid && this.forma.get('mail').touched;
  }

  get getNombre() {
    return this.formaServicio.get('nombre').invalid && this.formaServicio.get('nombre').touched;
  }

  get getApellido() {
    return this.formaServicio.get('apellido').invalid && this.formaServicio.get('apellido').touched;
  }

  get getTipo_documento() {
    return this.formaServicio.get('tipo_documento').invalid && this.formaServicio.get('tipo_documento').touched;
  }

  get getGender() {
    return this.formaServicio.get('gender').invalid && this.formaServicio.get('gender').touched;
  }

  get getDocumento() {
    return this.formaServicio.get('documento').invalid && this.formaServicio.get('documento').touched;
  }

  get getCelular() {
    return this.formaServicio.get('celular').invalid && this.formaServicio.get('celular').touched;
  }

  get getEmail() {
    return this.formaServicio.get('email').invalid && this.formaServicio.get('email').touched;
  }

  get getDepartment() {
    return this.formaServicio.get('department').invalid && this.formaServicio.get('department').touched;
  }

  get getCity() {
    return this.formaServicio.get('city').invalid && this.formaServicio.get('city').touched;
  }

  get getAddress() {
    return this.formaServicio.get('address').invalid && this.formaServicio.get('address').touched;
  }

  get getModelo() {
    return this.formaServicio.get('modelo').invalid && this.formaServicio.get('modelo').touched;
  }

  get getAlmacenamiento() {
    return this.formaServicio.get('almacenamiento').invalid && this.formaServicio.get('almacenamiento').touched;
  }

  get getFecha() {
    return this.formaServicio.get('fecha_compra').invalid && this.formaServicio.get('fecha_compra').touched;
  }

  get getAlmacen() {
    return this.formaServicio.get('almacen').invalid && this.formaServicio.get('almacen').touched;
  }

  get getImei() {
    return this.formaServicio.get('imei').invalid && this.formaServicio.get('imei').touched;
  }

  get getCantidad() {
    return this.formaServicio.get('cantidad').invalid && this.formaServicio.get('cantidad').touched;
  }
}
