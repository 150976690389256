import { Component, OnInit } from '@angular/core';
import { MethodsService } from "src/app/services/methods.service";
declare var $: any;

@Component({
  selector: 'app-retos',
  templateUrl: './retos.component.html',
  styleUrls: ['./retos.component.css'],

})
export class RetosComponent implements OnInit {

  public allCampaigns:any = [];
  public campaignsCache:any = [];
  constructor(private service: MethodsService) { }

  ngOnInit(): void {
    this.getCampañas();


   
  }

  slides = [342, 453, 846, 855, 234, 564, 744, 243];

  slideConfig = {
    "slidesToShow": 2,
    "slidesToScroll": 1,
    "nextArrow": "<div class='nav-btn next-slide'></div>",
    "prevArrow": "<div class='nav-btn prev-slide'></div>",
    "dots": true,
    "infinite": true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  addSlide() {
    this.slides.push(488)
  }

  removeSlide() {
    this.slides.length = this.slides.length - 1;
  }

  

  getCampañas(){
    this.service.getActiveCampaigns().subscribe((data: any) => {
      this.allCampaigns = data.data;
      this.campaignsCache = data.data;
      console.log(this.allCampaigns);

    sessionStorage.setItem('campañas' , JSON.stringify(this.allCampaigns));
     
     
    })
  }

  filtro(parameter:string){

  
console.log(parameter);
if (parameter=='todas') {
    this.allCampaigns = JSON.parse(sessionStorage.getItem('campañas'));
} else {
  this.allCampaigns = this.campaignsCache.filter(item => item.subcategory_app == parameter);
}




  }
  

}
