import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { tap , catchError } from "rxjs/operators";
import { of } from 'rxjs';

declare var swal: any;
@Injectable({
  providedIn: 'root'
})
export class MethodsService {

  constructor(private http: HttpClient) { }
  public url = "https://api.nacionsamsung.samsung.com.co/api/category/nacion_samsung"
  // public urlBase = 'https://876f-190-144-168-125.ngrok.io/';
  public urlBase = 'https://api.samsungparati.com.co/';
  public responseCampaigns:any;

  getActiveCampaigns() {

    return this.http.get(this.url, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    });
  }

  getMessage(code:any){
    switch (code) {
      case 409:
        swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          footer: 'Ya se encuentra registrado en esta campaña.'
        })
        break;

        case 200:

          break;

      default:
        break;
    }
  }


  registerUser(user , key){
    return this.http.post('https://api.nacionsamsung.samsung.com.co/api/userForm', user, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', 'Bearer '+key),
    }).pipe(
      catchError(err => {
        console.log(err);
        return of(err);
      })
    )
  }

  public sendCode = (code: string) => {
    const url = 'https://api.samsungparati.com.co/v1/api/Redenciones/RegistrarCodigoVoucher';
    const request = { codVoucher: code };
    const options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    return this.http.post(url, request, options).pipe(
      catchError(err => {
        console.log(err);
        return of(err);
      })
    );
  }

  public redimirPremioEntretenimiento = (json:any) => {
    const url = 'https://api.samsungparati.com.co/v1/api/Redenciones/RedimirPremioEntretenimiento';
    //const url = 'http://2b0a-190-144-168-125.ngrok.io/v1/api/Redenciones/RedimirPremioEntretenimiento';
    const request = json;
    const options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    console.log(request);
    return this.http.post(url, request, options).pipe(
      catchError(err => {
        console.log(err);
        return of(err);
      })
    );
  }

  public redimirPremioServicio = (json:any) => {
    const url = 'https://api.samsungparati.com.co/v1/api/Redenciones/RedimirPremioServicio';
    //const url = 'http://2b0a-190-144-168-125.ngrok.io/v1/api/Redenciones/RedimirPremioEntretenimiento';
    const request = json;
    const options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    console.log(request);
    return this.http.post(url, request, options).pipe(
      catchError(err => {
        console.log(err);
        return of(err);
      })
    );
  }

  public getTipoDocumento(){
    //https://api.samsungparati.com.co/v1​/api​/Redenciones​/GetTiposDocumento
    var url = this.urlBase + 'v1/api/Redenciones/GetTiposDocumento';
    return this.http.get(url, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    });
  }

  public getModelo(){
    var url = this.urlBase + 'v1/api/Redenciones/GetModelosCelular';
    return this.http.get(url, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    });
  }


  public getAlmacen(){
    var url = this.urlBase + 'v1/api/Redenciones/GetAlmacenes';
    return this.http.get(url, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    });
  }

  public sendImei = (imei: string) => {
    const url = this.urlBase + 'v1/api/Redenciones/ValidateIMEI/' + imei;
    const options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    return this.http.get(url, options).pipe(
      catchError(err => {
        console.log(err);
        return of(err);
      })
    );
  }

  public getDepartments(){
    var url = this.urlBase + 'v1/api/Redenciones/GetDepartamentos';
    return this.http.get(url, {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    });
  }

  public getCitiesByDepartment(idDepartment: number){
    var url = this.urlBase + 'v1/api/Redenciones/GetCiudadesByDeptoID/' + idDepartment;
    return this.http.get(url, {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    });
  }

  public redimirPremioS23Servicio = (json:any) => {
    const url = this.urlBase + 'v1/api/Redenciones/Registro2023';
    const request = json;
    const options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    console.log(request);
    return this.http.post(url, request, options).pipe(
      catchError(err => {
        console.log(err);
        return of(err);
      })
    );
  }

}
