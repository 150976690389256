import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  public images: { imgDesktop: string, imgMobile: string, index: number }[] = [
    {
      imgDesktop: './assets/images/bannerD.jpg',
      imgMobile: './assets/images/bannerM.jpg',
      index: 0
    }
    /*{
      imgDesktop: './assets/images/carousel1.jpg',
      imgMobile: './assets/images/carousel2.jpg',
      index: 1
    },
    {
      imgDesktop: './assets/images/carouselWin.jpg',
      imgMobile: './assets/images/carouselWin2.jpg',
      index: 2
    },
    {
      imgDesktop: './assets/images/rappiLaptop.png',
      imgMobile: './assets/images/rappiResponsive.png',
      index: 3
    }*/
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
