import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CheckboxRequiredValidator, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  public code: string;
  // Actions
  @Output() sendAction = new EventEmitter<any>();
  @Output() terms = new EventEmitter<any>();

  forma: FormGroup;

  constructor(private fb: FormBuilder) {
    this.crearFormulario();
  }

  ngOnInit(): void {
  }

  public crearFormulario = () => {
    this.forma = this.fb.group({
      code: ['', [Validators.required, Validators.minLength(15), Validators.maxLength(15), Validators.pattern('[0-9]+')]],
      politic: ['', [Validators.required]],
      terms: ['', [Validators.required]]
    });
  }

  get getCode (){
    return this.forma.get('code').invalid && this.forma.get('code').touched;
  }

  get getPolitic (){
    return this.forma.get('politic').invalid && this.forma.get('politic').touched;
  }

  get getTerms (){
    return this.forma.get('terms').invalid && this.forma.get('terms').touched;
  }

  public send = () => {
    // console.log(this.forma.status)
    if (this.forma.invalid) {
      return Object.values(this.forma.controls).forEach(control => {
        control.markAsTouched();
      });
    } else {
      this.sendAction.emit(this.forma.value.code);
    }
  }



  public popUpTerms =()=>{
    this.terms.emit(true);
    // console.log('www')
  }

}
