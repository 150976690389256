<div class="row">

    <div class="col-lg-12" style="margin-top: 0;">
    <p>si tienes alguna duda por favor contacta a nuestra línea 018000118418.</p>
    </div>

    <div class="col-lg-6">
        <p>
            Copyright© 1995-2020 Samsung. Todos los derechos reservados.
            Samsung Electronics Colombia S.A. NIT 830.028.931-5 Dirección Carrera 7 # 113 - 43 Of 607 (Torre Samsung)
            Teléfonos 600 12 72 - 01 8000 112 112 - # 726
        </p>
    </div>
    <div class="col-lg-6" id="contcomercio">
        <img src="./assets/images/iycomercio.png" alt="" class="comercio" srcset="">
    </div>

</div>

<div class="row" style="margin-top: 0px;">
    <div class="col-sm-12 col-md-6 col-lg-6" id="privacidad">
        <p>
            Privacidad
        </p>
        <p>
            Legal
        </p>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-6" id="redes">
        <a href="https://www.facebook.com/SamsungColombia/" target="_blank" rel="noopener noreferrer"><img
                src="./assets/images/face.png" alt="" srcset=""></a>
        <a href="https://twitter.com/SamsungCO" target="_blank" rel="noopener noreferrer"> <img
                src="./assets/images/twitter.png" alt="" srcset=""></a>
        <a href="https://www.instagram.com/samsungcolombia/" target="_blank" rel="noopener noreferrer"> <img
                src="./assets/images/insta.png" alt="" class="" srcset=""></a>
        <a href="https://www.youtube.com/user/samsungmobileco" target="_blank"><img src="./assets/images/youtube.png"
                alt="" class="" srcset=""></a>
    </div>

</div>

<div class="row" class="row" style="margin-top: 0; border:none !important">
  <p>Aplican términos y condiciones. Conoce más en <a href="https://www.samsung.com/co/info/tyc/" target="_blank">www.samsung.com/co/info/tyc</a></p>
</div>
<!-- div class="row" style="margin-top: 0; border:none !important">
    <p>
        PROMOCIÓN válida(i) para canales online desde el día 23 de septiembre de 2021 hasta el día 6 de octubre de
        2021, y (ii) para para canales físicos desde el día 27 de septiembre de 2021 hasta el día 6 de octubre de
        2021. En ambos casos, la promoción irá hasta su fecha de finalización o hasta agotar existencias, lo que
        ocurra primero. Son 1130 unidades disponibles de PRODUCTOS. “Preventa Offline Foldables” [Por la compra de
        un SAMSUNG GALAXY ZFlip 3 (SM-F711BZ), el CLIENTE recibirá (1) Samsung Galaxy BUDS 2 (SM-R177N), y
        encontrarás en la caja una tarjeta con un (1) Evoucher para redimir un (1) beneficio adicional. Por la
        compra de un SAMSUNG GALAXY ZFlip 3 (SM-F711BZ), el CLIENTE recibirá (1) Samsung Galaxy BUDS 2 (SM-R177N), y
        encontrará en la caja una tarjeta con un (1) Evoucher para redimir un (1) beneficio adicional, o por la
        compra de un SAMSUNG GALAXY ZFold 3 (SM-F926BZ), el CLIENTE recibirá (1) Samsung Galaxy Watch 4.40mm
        (SM-R860N), y encontrará en la caja una tarjeta con un (1) Evoucher para redimir un (1) beneficio adicional.
        Los PRODUCTOS y BENEFICIOS se entregarán a partir del 27 de septiembre de 2021. Los BENEFICIOS del Evoucher
        deberán redimirse por medio de samsungparati.com hasta el 30 de diciembre de 2021. Pasada dicha fecha, no
        podrás disfrutarlos. La PROMOCIÓN será aplicable a nivel nacional, mediante los canales online de las
        Tiendas Autorizadas Participantes. Los PRODUCTOS vienen únicamente con el cable de datos USB tipo C. El
        adaptador de carga, audífonos, así como cualquier otro accesorio se venden por separado. Utilice adaptadores
        de carga genuinos. El uso de otros adaptadores podría reducir el desempeño de la batería. El funcionamiento
        en 5G de los PRODUCTOS dependerá de la habilitación de la red 5G por parte del operador. Los servicios de
        roaming están habilitados en red 4G hasta que su operador lo indique. La velocidad variará de acuerdo a las
        condiciones del lugar y operador. Para mayor información comuníquese con su operador. El BENEFICIO no es
        canjeable por dinero en efectivo, por abonos a cuentas bancarias, abonos a tarjetas de crédito o débito, ni
        por cualquier otro beneficio diferente a lo expresado en los T&C de la PROMOCIÓN. Esta PROMOCIÓN es
        acumulable con otras promociones vigentes siempre y cuando así se informe expresamente. Los precios de
        venta, condiciones y tiempos de envío y entrega de los PRODUCTOS y/o BENEFICIOS dependerán de cada Tienda
        Autorizada Participante. Los detalles de la promoción se podrán consultar en
        <a href="http://"> www.samsung.com/co/offer.</a>

    </p>
</div -->
