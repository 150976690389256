import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Client } from '@models/client';
import { Gift } from '@models/gift';
declare var $: any;

@Component({
  selector: 'app-benefits',
  templateUrl: './benefits.component.html',
  styleUrls: ['./benefits.component.css']
})
export class BenefitsComponent implements OnInit {
  @Input() public client: Client;
  @Output() selectAction = new EventEmitter<any>();

  public campaignType = 0;
  public campaigns: Gift[] = [];
  public campaignSelected: Gift | null = null;
  public htmlValue = '';
  public tyc = false;
  public redemmed = true;

  constructor() {}

  ngOnInit(): void {
      this.setCampaignType(0);
  }

  public setCampaignType = (id: number) => {
    this.campaignType = id;
    this.campaigns = id !== 0 ? this.client.activeCampaigns.filter(c => c.giftType.giftTypeID === id) : this.client.activeCampaigns;
  }

  public setHtmlValue = (html: string, tyc: boolean) => {
    this.htmlValue = html;
    this.tyc = tyc;
  }

  public selectGift = (gift: Gift) => {
    this.selectAction.emit(gift);
  }

  public giftRedeemed = (id: number): boolean => {
    return this.client.voucher.redemtions.filter(v => v.giftCampaignID === id).length > 0;
  }

  public setCampaign = (campaign: Gift) => {
    this.htmlValue = campaign.comoRedimir;
    this.tyc = false;
    this.campaignSelected = campaign;
  }
}
