
<div class="contianer">
    <div class="row justify-content-center">
        <div class="main-text">
            <h1>¿Cómo participar?</h1>
            <p>Te invitamos a hacer parte de NACIÓN SAMSUNG y participar por increíbles premios. Es muy fácil solo debes:</p>
        </div>

    </div>
    <div class="row ">
        <div id="img" class="col-md-12 col-lg-6 ">
            <h1> #NaciónSamsung</h1>
            <img src="assets/images/img-participar.png" id="img-participar" class="img-fluid" alt="" srcset="">
        </div>
        <div class="col-md-12 col-lg-6 ">
            <div class="col-12 description">
                <div class="row" style="margin-top: 20px;">
                    <div class="col-2" style="text-align: center;"><strong class="number-blue">1. </strong></div>
                    <div class="col-10"> <p> Busca el desafío que más te guste.</p></div>
                  </div>
                  <div class="row" style="margin-top: 20px;">
                    <div class="col-2" style="text-align: center;"><strong class="number-blue">2. </strong></div>
                    <div class="col-10"> <p>Diligencia el formulario dejando tus &nbsp;&nbsp; datos personales.</p></div>
                  </div>
                  <div class="row" style="margin-top: 20px;">
                    <div class="col-2" style="text-align: center;"><strong class="number-blue">3. </strong></div>
                    <div class="col-10"> <p> Síguenos en nuestras redes sociales.</p></div>
                  </div>
                  <div class="row" style="margin-top: 20px;">
                    <div class="col-2" style="text-align: center;"><strong class="number-blue">4. </strong></div>
                    <div class="col-10"> <p> Ahora diviértete, grábate y sube tu video para ganar increíbles premios.</p></div>
                  </div>
                  <div class="row" style="margin-top: 20px;">
                    <div class="col-2" style="text-align: center;"><strong class="number-blue">5. </strong></div>
                    <div class="col-10"> <p> Participa activamente en nuestras redes sociales y no te pierdas el anuncio oficial de ganadores.</p></div>
                  </div>







<!--                 <p><strong class="number-blue">1. </strong> Busca el desafío que más te guste.</p>
                <p><strong class="number-blue">2. </strong> Diligencia el formulario dejando tus &nbsp;&nbsp; datos personales.</p>
                <p><strong class="number-blue">3. </strong> Síguenos en nuestras redes sociales. </p>
                <p><strong class="number-blue">4. </strong> Ahora diviértete, grábate y sube tu video para ganar increíbles premios.</p>
                <p><strong class="number-blue">5. </strong> Participa activamente en nuestras redes sociales y no te pierdas el anuncio oficial de ganadores.</p>
                 -->
              </div>
        </div>
    </div>

</div>

