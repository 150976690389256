import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtered'
})
export class FilteredPipe implements PipeTransform {

  transform(client: any): boolean {
    const cEntertainment = client.voucher.redemtions.filter(item => item.giftCampaign.giftType.giftTypeID === 2).length;
    if (cEntertainment > 0 && client.voucher.voucherCampaignID === 1) {
      return false;
    } else {
      return true ;
    }

  }
}
