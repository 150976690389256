<div class="div-benefits {{ client.voucher.voucherCampaignID === 1 ? 'black' : '' }} ">
  <h1>Hola, tienes los siguientes beneficios disponibles</h1>
  <!-- h2 class="txtnew" *ngIf="client.voucher.codeTypeID === 1">Recuerda que por ser Z lover ya Samsung te ha regalado la Garantía Extendida <br>
     y el Reemplazo de pantalla, solo debes redimirlos</h2 -->
  <div class="div-content-benefits">
    <!-- div class="links">
      <p class="{{ campaignType === 0 ? 'active' : '' }}" (click)="setCampaignType(0)">Todos</p>
      <p class="{{ campaignType === 2 ? 'active' : '' }}" (click)="setCampaignType(2)">Entretenimiento</p>
      <p class="{{ campaignType === 1 ? 'active' : '' }}" (click)="setCampaignType(1)">Servicio Posventa</p>
    </div -->
    <div class="grid-benefits row">
      <div class="col-12 col-lg-12" *ngFor="let campaign of campaigns , let i = index">
        <div class="benefit {{ campaign.giftType.giftTypeID === 1 && client.voucher.codeTypeID === 1 ? 'service' : '' }} row">
          <h1 class="col-12">{{ campaign.campaignName }}</h1>
          <div class="col-12 col-sm-4 col-lg-6 div-img">
            <img class="img" src="{{ campaign.campaignImage }}">
          </div>
          <div class="col-12 col-sm-8 col-lg-6 general">
            <p class="description">{{ campaign.campaignDescription }}</p>
            <p class="time">{{ campaign.tiempoCobertura }}</p>
            <!-- p class="description"><b>Cantidad disponibles: </b>{{ campaign.cantCodigosDisponibles }}</p -->
            <p class="description"></p>
            <p class="date"><b>Válido hasta: </b>{{ campaign.finishDate | date: 'dd/MM/yyyy' }}</p>
            <div class="buttons row">
              <button class="link" (click)="setCampaign(campaign)">Conoce más</button>
              <div *ngIf="(client | filtered)">
                <button class="button" (click)="selectGift(campaign)" *ngIf="campaign.giftType.giftTypeID === 2 && client.voucher.voucherCampaignID === 1 || client.voucher.voucherCampaignID === 2">
                  <b>Lo quiero</b>
                </button>
              </div>
              <button class="button" (click)="selectGift(campaign)" *ngIf="campaign.giftType.giftTypeID === 1 && client.voucher.voucherCampaignID === 1">
                <b>Activalo</b>
              </button>
            </div>
          </div>
          <div class="redeemed-benefit" *ngIf="giftRedeemed(campaign.giftCampaignID)">
            <h1 cla>E-voucher Redimido</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="pop-up-benefit" *ngIf="campaignSelected !== null">
  <div class="pop-up-benefit-background" (click)="campaignSelected = null"></div>
  <div class="pop-up-benefit-content animate__bounceIn" style="overflow: scroll;">
    <h1>{{ campaignSelected.campaignName }}</h1>
    <h2>E-voucher beneficio Galaxy S23 5G Series bundle</h2>
    <p class="description">{{ campaignSelected.campaignDescription }}</p>
    <p class="date"><b>Válido hasta: </b>{{ campaignSelected.finishDate | date: 'dd/MM/yyyy' }}</p>
    <img class="img" src="{{ campaignSelected.campaignImage }}">
    <div class="tyc row">
      <div class="col-6 right"><p class="{{ !tyc ? 'active' : '' }}" (click)="setHtmlValue(campaignSelected.comoRedimir, false)">¿Cómo redimir tu e-voucher?</p></div>
      <div class="col-6 left"><p class="{{ tyc ? 'active' : '' }}" (click)="setHtmlValue(campaignSelected.terminosCondiciones, true)">Términos y condiciones</p></div>
      <div class="col-12 containerInner" >
        <p class="tyc-html-value" [innerHTML]="htmlValue">
        </p>
      </div>
    </div>
    <div class="buttons">
      <button class="link" (click)="campaignSelected = null">Cancelar</button>
      <button class="button" (click)="selectGift(campaignSelected)">Lo quiero</button>
    </div>
  </div>
</div>

