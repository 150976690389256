export class User {
    public name: string;
    public last_name: string;
    public mail: string;
    public tipo_documento:string;
    public numero_documento:string;
    public celular:string;
    public fecha_compra:Date;
    public modelo:string;
    public almacen:string;
    public imei:string;
    public cantidad_productos:number;

    public instagramUser: string;
    public youtubeUser: string;
    public phone: number;
    public join: string;
    public politics: any;
    public terms: any;
    public interestCategory: string;
    public codigoReferido: string;
    public btsArmy: any;
    public id_app: number;
    public file: Image64;


    constructor() {
        this.userInit();
    }

    userInit() {

        this.name = null;
        this.last_name = null;
        this.mail = null;
        this.phone = null;
        this.instagramUser = null;
        this.youtubeUser = null;
        this.join = null;
        this.politics = null;
        this.terms = null;
        this.btsArmy = false;
        this.interestCategory = "Design";
        this.id_app = null;

    }


    getJsonFrm() {
        return {
            email: this.mail,
            last_name: this.last_name,
            name: this.name,
            mobile_phone: this.phone,
            extra: {
                'user-youtube': this.instagramUser,
                'user-instagram': this.youtubeUser,
                'codigo-referido': this.codigoReferido,
                btsArmy: this.btsArmy
            },
            file: this.file,
            application_id: this.id_app,
            id_app: this.id_app,
            accept_terms: this.terms === true ? 1 : 0,
            accept_politics: this.politics === true ? 1 : 0
        };
    }

    getJsonEntreteinmentForm(){
        return{
            nombres: this.name,
            apellidos: this.last_name,
            email: this.mail,
            imagenFactura:this.file
          }
    }
}

/*     "file": {
        "height": 491,
        "timestamp": "2019-01-06T17:16:40",
        "uri": "group-487.png",
        "fileName": "group-487.png",
        "data": "iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGASURBVHgBjVO9TgJBEP52TgqhkAJrzieAxBcALbSDkkR8BX9aNREMWqq8gYWFjQReQH0Byb0B12uBBURDbsfZO/k5OOEmuc3ud/N9M7M7ozBnowoKaxaVAC4zlG0wBXZkdb49XV9/gjvrr8YbLiONFF0ycIIlJoR7JHRdPaA/ETBkTtGrbPOIZeyoBBeNCPlniRyf7MfNY+RzoLgCmy3qreSk0kBSvg93AnmeLhKsQGllzOsu1GkrhBFRmThG6qp6B2Sy4Od6GFcomTuYCmzaUDddIJubOu0dA/tH4JaQ3zvz2jaFjoO+X6c6fwlERBCHt2BDbF0hMjs+oJ6UYU+QjGRxIQLJDWD4JQCDz7Zl318ky3OSZoTz+nTBjZ2ALCLc2I0k/0k4alRFwWK/icImmYwF/7MfT29R4hFvrNBc+GuIS8jMaJq58C+R1nQN/sDENal9aDjCNYvpadPbkZlERFYDmYP2zDCFHKS1tUU1ueGc3/MB6jKrjibdNiXP+v8CkbmKMn9dfKkAAAAASUVORK5CYII="
    }, */



export interface Image64 {
    height: number;
    timestamp: string;
    uri: string;
    fileName: string;
    data: string;
}