<div class="container-fluid">
    <div class="row justify-content-center">
        <div class="main-txt">
            <h1>Participa con los retos</h1>
            <p>Te invitamos a revisar las categorías participantes:</p>
        </div>

    </div>

    <div class="row justify-content-center" style="margin-top: 15px;">
 

      
      <div class="menu">
          <p (click)="filtro('todas')"> Todas  </p>
          <p (click)="filtro('music')">  Música</p>
          <p (click)="filtro('social')"> Social</p>
          <p (click)="filtro('sport')">  Deportes</p>


  
      </div> 
    </div>



<!-- carousel -->





<div class="carusel" style="margin-top: 55px;">


<ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" >
<div ngxSlickItem *ngFor="let item of allCampaigns" class="slide">
  <div class="card animate__animated animate__fadeIn" style="border-radius: 16px;">
    <img src="{{item.email_img}}">
    <h2 style="margin-top: 50px;">{{item.title}}</h2>
    <div class="card-body">
     
      <div class="card-text" [innerHTML]="item.description | html"></div>
    </div>
  </div>  

  <br><br><br>
</div>
</ngx-slick-carousel>
</div>




    </div>
