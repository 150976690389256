
<div class="row">
  <div class="icon">
    <img src="./assets/images/samsung.png" alt="" srcset="">
  </div>
</div>

<div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
  <ol class="carousel-indicators">
    <li data-target="#carouselExampleIndicators" data-slide-to="{{ image.index }}" class="active" *ngFor="let image of  images"></li>
  </ol>
  <div class="carousel-inner border">
    <div class="carousel-item {{ image.index === 0 ? 'active' : '' }}" *ngFor="let image of  images">
      <img id="mainImg" class="img-fluid" src="{{ image.imgDesktop }}" alt="First slide">
      <img id="responsiveImg" class="img-fluid" src="{{ image.imgMobile }}" alt="First slide">
    </div>
  </div>
  <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
    <span  class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>

<div class="row" id="rowText" >
  <div class="col-md-12 col-lg-12" style="text-align: center;">
    <!-- h1>E-voucher</h1 -->
    <h1>¡Tu nuevo Galaxy no viene solo!</h1>
    <p>Compra ahora tu nuevo Galaxy S23 5G Series y podrás acompañarlo con unos increíbles Galaxy Buds2 y el case que más te guste. Disfrútalo ya:</p>
    <!-- p>Por haber comprado tu Samsung Galaxy Z Flip3 o Galaxy Z Fold3, tienes beneficios exclusivos para redimir. Hazlo
      ya y empieza a disfrutar</p -->
  </div>
</div>

<div class="row" id="rowSteps" >
  <div class="col-sm-12 col-md-4 col-lg-4">
    <div class="img">
      <img  src="./assets/images/step1.png" alt="" srcset="">
    </div>
    <div class="txtStep">
      <h1>1.</h1>
      <p>Ingresa el IMEI de tu celular<br>para validarlo.</p>
    </div>

  </div>

  <div class="col-sm-12 col-md-4 col-lg-4">
    <div class="img">
      <img src="./assets/images/step2.png" alt="" srcset="">
    </div>
    <div class="txtStep">
      <h1>2.</h1>
      <p>Selecciona el beneficio<br>y llena el formulario con tus datos.</p>
    </div>

  </div>

  <div class="col-sm-12 col-md-4 col-lg-4">
    <div class="img">
      <img src="./assets/images/step3.png" alt="" srcset="">
    </div>
    <div class="txtStep">
      <h1>3.</h1>
      <p>Recibirás un e-mail con la<br>confirmación y los pasos a seguir<br>para disfrutar tu beneficio.</p>
    </div>

  </div>

  <!-- div class="col-sm-12 col-md-3 col-lg-3">
    <div class="img">
      <img src="./assets/images/4.png" alt="" srcset="">
    </div>
    <div class="txtStep">
      <h1>4.</h1>
      <p>Recibiras un e-mail con la confirmación y pasos a seguir para disfrutar tu beneficio.</p>
    </div>
  </div -->
</div>
