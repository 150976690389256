import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IntroComponent } from '@components/intro/intro.component';
import { RetosComponent } from '@components/retos/retos.component';
import { FormularioComponent } from '@components/formulario/formulario.component';
import { SeguidoresComponent } from '@components/seguidores/seguidores.component';
import { MainComponent } from '@components/main/main.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { HttpClientModule } from '@angular/common/http';
import { HtmlPipe } from '@pipes/html.pipe';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { FormsModule , ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from '@components/header/header.component';
import { BenefitsComponent } from '@components/benefits/benefits.component';
import { RegisterComponent } from '@components/register/register.component';
import { FooterComponent } from './components/footer/footer.component';
import { SpinerComponent } from './components/spiner/spiner.component';
import { FilteredPipe } from './pipes/filtered.pipe';



@NgModule({
  declarations: [
    AppComponent,
    IntroComponent,
    RetosComponent,
    FormularioComponent,
    SeguidoresComponent,
    MainComponent,
    HtmlPipe,
    HeaderComponent,
    BenefitsComponent,
    RegisterComponent,
    FooterComponent,
    SpinerComponent,
    FilteredPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CarouselModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SlickCarouselModule,
    FormsModule,
    ReactiveFormsModule

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
